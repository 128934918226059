export enum PathNames {
    LOANS = "loans",
    PIPELINE = "pipeline",
    CUSTOMERS = "customers",
    CT_VERIFICATION = "ct-verification"
}

export enum PathQuery {
    LOANS = "disbursed",
    PIPELINE = "pipeline",
}